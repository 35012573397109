import { render, staticRenderFns } from "./Teams.vue?vue&type=template&id=66b7a2f4&scoped=true&"
import script from "./Teams.vue?vue&type=script&lang=js&"
export * from "./Teams.vue?vue&type=script&lang=js&"
import style0 from "./Teams.vue?vue&type=style&index=0&id=66b7a2f4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66b7a2f4",
  null
  
)

export default component.exports