<template>
    <button
        @click="showProfile"
        class="button button-profile"
        disabled
    >
        {{ 'Profil' }}
    </button>
</template>

<script>
    export default {
        methods: {
            showProfile() {
            },
        },
    }
</script>
